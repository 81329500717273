<template>
  <div>
    <v-card fluid :dark="$dark.get()" class="modal">
      <v-card-title class="title header"
        >{{
          isCompany ? "Управление компаниями" : "Управление типами доставок"
        }}
        <div>
          <v-btn
            small
            :color="!isCompany ? 'primary' : ''"
            @click="isCompany = false"
            >Типы доставок</v-btn
          >
          <v-btn
            small
            :color="isCompany ? 'primary' : ''"
            @click="isCompany = true"
            >Компании</v-btn
          >
        </div>
      </v-card-title>
      <Company v-if="isCompany" />
      <DeliveryTypes v-else />
    </v-card>
  </div>
</template>
<script>
import Company from "@/components/Logistic/Company";
import DeliveryTypes from "@/components/Logistic/DeliveryTypes";

export default {
  components: {
    Company,
    DeliveryTypes,
  },
  data() {
    return {
      isCompany: false,
    };
  },
  async mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    gap: 10px;
  }
}
</style>
