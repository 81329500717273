<template>
  <v-card-text class="diagram">
    <loader v-if="loading"></loader>
    <div class="create-btn">
      <v-btn small color="success" @click="openCreateModal = true"
        >Добавить доставку</v-btn
      >
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Название</th>
          <th>Длина</th>
          <th>Ширина</th>
          <th>Высота</th>
          <th>Вес</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in DELIVERY_TYPES" :key="item.id">
          <td>{{ item.name }}</td>
          <td>{{ item.maxLength }}см</td>
          <td>{{ item.maxWidth }}см</td>
          <td>{{ item.maxHeight }}см</td>
          <td>{{ item.maxWeight }}кг</td>
          <td class="table-btns">
            <v-btn icon color="primary" @click="enableEditMode(item)">
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <DeleteDialog
      :show="showDeleteModal"
      @close-modal="showDeleteModal = !showDeleteModal"
      @delete-modal="deleteFeature()"
    />
    <v-dialog v-model="openCreateModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          {{ editMode ? "Изменить" : "Создать" }}
          тип доставки
        </v-card-title>
        <v-card-text>
          <v-form ref="typeForm">
            <v-text-field
              v-model="payload.name"
              :rules="[$validate.required]"
              label="Название"
              type="text"
              required
              clearable
            ></v-text-field>
            <v-text-field
              v-model="payload.maxLength"
              :rules="[$validate.required]"
              label="Максимальная длина в см"
              type="number"
              required
              clearable
            ></v-text-field>
            <v-text-field
              v-model="payload.maxWidth"
              :rules="[$validate.required]"
              label="Максимальная ширина в см"
              type="number"
              required
              clearable
            ></v-text-field>
            <v-text-field
              v-model="payload.maxHeight"
              :rules="[$validate.required]"
              label="Максимальная высота в см"
              type="number"
              required
              clearable
            ></v-text-field>
            <v-text-field
              v-model="payload.maxWeight"
              :rules="[$validate.required]"
              label="Максимальный вес в кг"
              type="number"
              required
              clearable
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="openCreateModal = false">Закрыть</v-btn>
          <v-btn
            color="primary"
            @click="editMode ? editType() : createType()"
            >{{ editMode ? "Изменить" : "Создать" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>
import Loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import ShowMessage from "@/Functions/message";
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
export default {
  name: "DeliveryTypes",
  components: {
    Loader,
    DeleteDialog,
  },
  props: {},
  data() {
    return {
      loading: false,
      showDeleteModal: false,
      deletedFeatureId: null,
      openCreateModal: false,
      payload: {},
      editMode: false,
    };
  },
  computed: {
    ...mapGetters({
      DELIVERY_TYPES: "Logistic/DELIVERY_TYPES",
    }),
  },
  watch: {
    openCreateModal(val) {
      if (!val) {
        this.payload = {};
        this.editMode = false;
        this.$refs.typeForm.resetValidation();
      }
    },
  },
  mounted() {
    if (!this.DELIVERY_TYPES.length) {
      this.setAll();
    }
  },
  methods: {
    ...mapActions({
      GET_DELIVERY_TYPES: "Logistic/GET_DELIVERY_TYPES",
    }),
    async setAll() {
      this.loading = true;
      await this.GET_DELIVERY_TYPES();
      this.loading = false;
    },
    async createType() {
      if (!this.$refs.typeForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      let response = await api.Logistic.addType(this.payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Тип доставки создан", true);
        this.setAll();
      }
      this.openCreateModal = false;
    },

    enableEditMode(item) {
      this.openCreateModal = true;
      this.editMode = true;
      this.payload = item;
    },
    async editType() {
      if (!this.$refs.typeForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      let response = await api.Logistic.editType(this.payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Тип доставки изменен", true);
        this.setAll();
      }
      this.openCreateModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-btns {
  width: 110px;
}
.create-btn {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>
